import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  ScrollView,
  TouchableOpacity,
  Platform,
  Alert,
  Linking,
} from "react-native";
import * as Notifications from "expo-notifications";
import { Colors, Fonts } from "../styles/styles";
import { AppContext } from "../../AppContext";
import profileImages from "../img/profileimage.js";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import EmptyContent from "./EmptyContent";
import { SocketContext } from "../../SocketContext.js";

const translations = {
  ESP: {
    activateNotifications: "¿Quieres que te avisemos cuando otros vecinos te escriban un mensaje?",
    alertTitle: "Permite que Trocalia te envíe notificaciones",
    alertMessage: "Toca configuración para actualizar tus preferencias.",
    noOption: "Ahora no",
    settingsOption: "Configuración",
  },
  CAT: {
    activateNotifications: "Vols que t'avisem quan altres veïns t'escriguin un missatge?",
    alertTitle: "Permet que Trocalia t'envii notificacions",
    alertMessage: "Toca configuració per actualitzar les teves preferències.",
    noOption: "Ara no",
    settingsOption: "Configuració",
  },
  ENG: {
    activateNotifications: "Do you want us to notify you when other neighbors write you a message?",
    alertTitle: "Allow Trocalia to send you notifications",
    alertMessage: "Tap settings to update your preferences.",
    noOption: "Not now",
    settingsOption: "Settings",
  },
};

const Mensajes = () => {
  const { token, serverAddress, userData, miID, language } = useContext(AppContext);
  const [chats, setChats] = useState([]);
  const navigation = useNavigation();
  const [isNotificationsGranted, setIsNotificationsGranted] = useState(false);
  const { shouldFetchChats, resetShouldFetchChats } = useContext(SocketContext);
  


  // Revisar el estado de permisos de notificación
  useEffect(() => {
    const checkNotificationPermissions = async () => {
      const { status } = await Notifications.getPermissionsAsync();
      setIsNotificationsGranted(status === "granted");
    };
    checkNotificationPermissions();
  }, []);

  const requestPushPermissions = async () => {
    const { status } = await Notifications.requestPermissionsAsync();
    setIsNotificationsGranted(status === "granted");
    
    if (status !== "granted") {
      Alert.alert(
        translations[language].alertTitle,
        translations[language].alertMessage,
        [
          { text: translations[language].noOption, style: "cancel" },
          { text: translations[language].settingsOption, onPress: () => Linking.openSettings() },
        ]
      );
    }
  };

  useEffect(() => {
    fetchChats();
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      // Función para cargar los chats cuando la pantalla obtiene el foco
      fetchChats();
    }, [])
  );

  useEffect(() => {
    if (shouldFetchChats) {
      fetchChats();
      resetShouldFetchChats();
    }
  }, [shouldFetchChats, resetShouldFetchChats]);
  

  const fetchChats = async () => {
    try {
      const response = await fetch(`${serverAddress}/api/v1/perfils/meusxats`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.status === "OK") {
        const chatsConFechaReciente = data.data.map((chat) => ({
          ...chat,
          fechaReciente: chat.missatges.reduce(
            (fechaReciente, mensaje) =>
              new Date(mensaje.dataCreacio) > new Date(fechaReciente)
                ? mensaje.dataCreacio
                : fechaReciente,
            chat.missatges[0].dataCreacio
          ),
        }));

        const chatsOrdenados = chatsConFechaReciente.sort(
          (a, b) => new Date(b.fechaReciente) - new Date(a.fechaReciente)
        );

        setChats(chatsOrdenados);
      } else {
        setChats([]);
      }
    } catch (error) {
      setChats([]);
    }
  };

  const esBloqueadoPorMi = (chat) => {
    return chat.usu_bloquejadorXat === miID;
  };

  const navigateToChat = (chat) => {
    if (!userData || !userData.data || userData.data.length === 0) {
      return;
    }
    const otherUserId =
      chat.usu_creadorXat === userData.data[0].id_usuari
        ? chat.usu_receptorXat
        : chat.usu_creadorXat;
    const otherUserPhoto =
      chat.usu_creadorXat === userData.data[0].id_usuari
        ? chat.fotoReceptor
        : chat.fotoCreador;

    const partnerDetails = getChatPartnerDetails(chat);

    navigation.navigate("ChatScreen", {
      otherUserId,
      otherUserPhoto,
      otherUserName: partnerDetails.nombre,
      otherUserSurname: partnerDetails.apellido,
    });
  };

  const debeMostrarEnNegrita = (mensaje) => {
    const idUsuarioActual = userData.data[0].id_usuari;
    return (
      mensaje.usuariEmisor !== idUsuarioActual &&
      mensaje.situacioMissatge !== "L" &&
      mensaje.situacioMissatge !== null
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    if (date.toDateString() === now.toDateString()) {
      return `${date.getHours()}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    } else {
      return `${date.getDate()}/${date.getMonth() + 1} ${date.getHours()}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    }
  };

  const getChatPartnerDetails = (chat) => {
    if (!userData || !userData.data || userData.data.length === 0) {
      return null;
    }
    const currentUser = userData.data[0].id_usuari;
    if (chat.usu_receptorXat === currentUser) {
      return {
        nombre: chat.nomUsuCreador,
        apellido: chat.cognomUsuCreador,
        foto: chat.fotoCreador,
        tipo: chat.tipoUsuCreador,
      };
    } else {
      return {
        nombre: chat.nomUsuReceptor,
        apellido: chat.cognomUsuReceptor,
        foto: chat.fotoReceptor,
        tipo: chat.tipoUsuReceptor,
      };
    }
  };

  if (chats.length === 0) {
    return <EmptyContent contentType="mensajes" />;
  }

  return (
    <ScrollView contentContainerStyle={styles.scrollViewContainer}>
      {/* Mostrar botón para activar notificaciones si no están concedidas */}
      {!isNotificationsGranted && Platform.OS !== "web" && (
        <TouchableOpacity
          onPress={requestPushPermissions}
          style={styles.notificationPrompt}
        >
          <Text style={styles.notificationText}>
            {translations[language].activateNotifications}
          </Text>
          <Text style={styles.iconText}>💬</Text>
        </TouchableOpacity>
      )}

      {/* Renderizado de los chats */}
      {chats.map((chat, index) => {
        const partner = getChatPartnerDetails(chat);
        if (!partner) {
          return null;
        }
        const bloqueadoPorMi = esBloqueadoPorMi(chat);
        return (
          <TouchableOpacity key={index} onPress={() => navigateToChat(chat)}>
            <View
              style={[
                styles.cardContainer,
                bloqueadoPorMi ? styles.bloqueadoStyle : {},
              ]}
            >
              <Image
                style={[
                  styles.instanceItem,
                  partner.tipo === "Premium"
                    ? styles.premiumBorder
                    : styles.standardBorder,
                  !partner.foto ? styles.defaultImageBackground : {},
                ]}
                source={
                  partner.foto
                    ? { uri: `${serverAddress}/fotos/${partner.foto}` }
                    : profileImages[chat.usu_receptorXat % 10] ||
                      require("../img/profiles/default.png")
                }
              />
              {partner.tipo === "Premium" && (
                <Text style={styles.proText}>PRO</Text>
              )}
              {bloqueadoPorMi && (
                <Image
                  style={styles.blockIcon}
                  source={require("../img/icons/block2.png")}
                />
              )}
              <View style={styles.textContainer}>
                <Text style={[Fonts.dmSerifDisplayRegular16, styles.title]}>
                  {partner.nombre} {partner.apellido}
                </Text>
                <Text
                  style={[
                    Fonts.poppinsRegular14,
                    styles.descriptionText,
                    debeMostrarEnNegrita(chat.missatges[0])
                      ? styles.negrita
                      : { color: Colors.grayDark },
                  ]}
                  numberOfLines={2}
                >
                  {chat.missatges[0].texte}
                </Text>
              </View>
              <Text style={[Fonts.poppinsRegular12, styles.dateText]}>
                {formatDate(chat.missatges[0].dataCreacio)}
              </Text>
            </View>
          </TouchableOpacity>
        );
      })}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollViewContainer: {
    flexGrow: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingVertical: 5, // Reducido para disminuir el espacio arriba del componente informativo
    paddingHorizontal: 10,
  },
  notificationPrompt: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between", // Para alinear el texto y el icono de campana
    backgroundColor: Colors.white,
    width: 340,
    paddingVertical: 6, // Ajuste para que no quede demasiado alto
    paddingHorizontal: 12,
    borderRadius: 16,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: Colors.primary,
    
  },
  iconText: {
    fontSize: 20, // Reducido para que la campana no sea tan grande
    color: Colors.primary,
  },
  notificationText: {
    color: Colors.primary,
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
    flex: 1, // Para que el texto ocupe el espacio disponible y no se salga del recuadro
  },
  cardContainer: {
    flexDirection: "row",
    marginTop: 0,
    marginBottom: 16,
    alignItems: "center",
    backgroundColor: Colors.grayLighter,
    borderRadius: 16,
    height: 90,
    justifyContent: "flex-start",
    padding: 16,
    width: 340,
    alignSelf: "center",
    borderWidth: 1,
    borderColor: Colors.grayLight,
  },
  instanceItem: {
    borderRadius: 40,
    width: 71,
    height: 71,
    backgroundColor: Colors.primary,
  },
  proText: {
    position: "absolute",
    top: 4,
    left: 4,
    color: Colors.secondary,
    ...Fonts.poppinsSemiBold14,
  },
  textContainer: {
    marginLeft: 16,
    flex: 1,
    justifyContent: "space-between",
  },
  title: {
    marginBottom: 2,
  },
  premiumBorder: {
    borderWidth: 2,
    borderColor: Colors.secondary,
  },
  standardBorder: {
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  defaultImageBackground: {
    backgroundColor: Colors.primary,
  },
  descriptionText: {
    marginTop: 6,
  },
  dateText: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  bloqueadoStyle: {
    backgroundColor: "#D3D3D3",
  },
  blockIcon: {
    position: "absolute",
    right: 10,
    bottom: 10,
    width: 25,
    height: 22,
  },
  negrita: {
    ...Platform.select({
      ios: {
        textShadowColor: "rgba(0, 0, 0, 0.6)",
        textShadowOffset: { width: 0, height: 0 },
        textShadowRadius: 2,
      },
      android: {
        fontWeight: "bold",
      },
      default: {
        fontWeight: "bold",
      },
    }),
  },
});

export default Mensajes;

