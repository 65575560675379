import React, { useContext, useState } from "react";
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { Colors } from "../styles/styles";
import { AppContext } from "../../AppContext";
import { useNavigation } from "@react-navigation/native";

const translations = {
  ESP: {
    title: "Eliminar cuenta",
    message:
      "Esto es definitivo: una vez que elimines tu cuenta, no hay vuelta atrás. ¿Estás seguro/a de querer continuar?",
    delete: "Sí, eliminar",
    cancel: "No, cancelar",
    okdeleted: "Cuenta eliminada correctamente",
    salir: "Salir",
  },
  CAT: {
    title: "Eliminar compte",
    message:
      "Això és definitiu: un cop elimines el teu compte, no hi ha marxa enrere. Estàs segur/a de voler continuar?",
    delete: "Sí, eliminar",
    cancel: "No, cancel·lar",
    okdeleted: "Compte eliminat correctament",
    salir: "Sortir",
  },
  ENG: {
    title: "Delete account",
    message:
      "This is final: once you delete your account, there's no going back. Are you sure you want to proceed?",
    delete: "Yes, delete",
    cancel: "No, cancel",
    okdeleted: "Account deleted successfully",
    salir: "Exit",
  },
};

const DeleteAccount = ({ isVisible, onClose }) => {
  const { language, serverAddress, token, updateToken } = useContext(AppContext);
  const t = translations[language];
  const [isDeleted, setIsDeleted] = useState(false);
  const navigation = useNavigation();

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ actuacio: "DELETEPERFIL" }),
        }
      );
      const data = await response.json();
      if (data && data.status === "OK") {
        setIsDeleted(true);
        updateToken(null); // Utiliza updateToken para manejar el token
      }
    } catch (error) {
      //console.error("Error al eliminar la cuenta:", error);
    }
  };

  const handleExit = () => {
    onClose();
    navigation.reset({
      index: 0,
      routes: [{ name: "Login" }],
    });
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={() => {}} // Esto deshabilita cerrar el modal con el botón de atrás en Android
    >
      <View style={styles.modalBackground}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>{t.title}</Text>

          {isDeleted ? (
            <>
              <Text style={styles.modalMessage}>{t.okdeleted}</Text>
              <TouchableOpacity
                style={[styles.button, styles.cancelButton]}
                onPress={handleExit}
              >
                <Text style={styles.buttonText}>{t.salir}</Text>
              </TouchableOpacity>
            </>
          ) : (
            <>
              <Text style={styles.modalMessage}>{t.message}</Text>
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={[styles.button, styles.deleteButton]}
                  onPress={handleDelete}
                >
                  <Text style={styles.buttonText}>{t.delete}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.button, styles.cancelButton]}
                  onPress={onClose}
                >
                  <Text style={styles.buttonText}>{t.cancel}</Text>
                </TouchableOpacity>
              </View>
            </>
          )}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary,
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    paddingVertical: 20,
    paddingHorizontal: 5,
    elevation: 2,
    width: "40%",
    margin: 5,
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
  },
});
export default DeleteAccount;
