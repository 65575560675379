import React, { useContext, useState, useEffect } from "react";
import { View, Image, TouchableOpacity } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Colors, Fonts } from "../styles/styles.js";
import HomeScreen from "../screens/HomeScreen";
import TrocScreen from "../screens/TrocScreen";
import SearchScreen from "../screens/SearchScreen";
import ProfileScreen from "../screens/ProfileScreen";
import MessagesScreen from "../screens/MessagesScreen";
import { AppContext } from "../../AppContext";
import { SocketContext } from "../../SocketContext";
import NeedRegister from "../selectables/NeedRegister.js";
import { AppState } from "react-native";


// Importación de íconos
import IniciIcon from "../img/navbar/inici.png";
import TrocIcon from "../img/navbar/troc.png";
import BuscarIcon from "../img/navbar/buscar.png";
import MissatgesIcon from "../img/navbar/missatge.png";
import PerfilIcon from "../img/navbar/perfil.png";

const translations = {
  ESP: {
    inici: "Inicio",
    troc: "Troc",
    buscar: "Buscar",
    missatges: "Mensajes",
    perfil: "Perfil",
  },
  CAT: {
    inici: "Inici",
    troc: "Troc",
    buscar: "Cercar",
    missatges: "Missatges",
    perfil: "Perfil",
  },
  ENG: {
    inici: "Main",
    troc: "Troc",
    buscar: "Search",
    missatges: "Messages",
    perfil: "Profile",
  },
};

const Tab = createBottomTabNavigator();

export const NavBar = () => {
  const { language, userData,  } = useContext(AppContext);
  const {fetchNotifications} = useContext(SocketContext);
  const {
    hasUnseenMessageNotifications,
    hasUnseenGeneralNotifications,
    setShowNotificationDot,
  } = useContext(SocketContext);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);
  const { clearNotifications } = useContext(SocketContext);
  const { clearMessageNotificationsDot } = useContext(SocketContext);
  const routeNameToUniqueIdentifier = {
    [translations[language].inici]: "inici",
    [translations[language].troc]: "troc",
    [translations[language].buscar]: "buscar",
    [translations[language].missatges]: "missatges",
    [translations[language].perfil]: "perfil",
  };

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (nextAppState === 'active') {
        //console.log('La app ha vuelto a estar activa!');
        fetchNotifications();
      }
    });
  
    return () => {
      subscription.remove();
    };
  }, []);
  const checkAccess = (translatedRouteName) => {
    const uniqueIdentifier = routeNameToUniqueIdentifier[translatedRouteName];
    const routesRequiringRegistration = [ "missatges", "perfil"];

    if (
      userData.data[0].tipusUsuari === "Guest" &&
      routesRequiringRegistration.includes(uniqueIdentifier)
    ) {
      setIsNeedRegisterVisible(true);
      return false; // Detener la navegación si el usuario es invitado y la ruta requiere registro
    }
    return true; // Permitir la navegación
  };
  // useEffect(() => {
  //   // Aquí manejas la lógica de visibilidad del dot basado en las notificaciones no vistas
  //   const shouldShowDot = hasUnseenMessageNotifications && hasUnseenGeneralNotifications;
  //   setShowNotificationDot(shouldShowDot);
  // }, [hasUnseenMessageNotifications, hasUnseenGeneralNotifications, setShowNotificationDot]);

  return (
    <>
      <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ focused, color, size }) => {
            let iconName;
            switch (route.name) {
              case translations[language].inici:
                iconName = IniciIcon;
                break;
              case translations[language].troc:
                iconName = TrocIcon;
                break;
              case translations[language].buscar:
                iconName = BuscarIcon;
                break;
              case translations[language].missatges:
                iconName = MissatgesIcon;
                break;
              case translations[language].perfil:
                iconName = PerfilIcon;
                break;
              default:
                iconName = IniciIcon;
            }

            const shouldShowDot =
              route.name === translations[language].missatges &&
              (hasUnseenMessageNotifications || hasUnseenGeneralNotifications);

            return (
              <View>
                <Image
                  source={iconName}
                  style={{
                    tintColor: focused ? Colors.primary : Colors.inactive,
                    width: 38,
                    height: 38,
                  }}
                />
                {shouldShowDot && (
                  <View
                    style={{
                      position: "absolute",
                      right: -6,
                      top: -3,
                      backgroundColor: "red",
                      borderRadius: 6,
                      width: 12,
                      height: 12,
                    }}
                  />
                )}
              </View>
            );
          },
          tabBarButton: (props) => (
            <TouchableOpacity
              {...props}
              onPress={(e) => {
                const canNavigate = checkAccess(route.name);
                if (canNavigate && props.onPress) {
                  props.onPress(e);
                }
                if (route.name === translations[language].missatges) {
                  clearMessageNotificationsDot(); // Limpiar solo el dot de notificaciones de mensajes
                }
              }}
            />
          ),
          tabBarActiveTintColor: Colors.primary,
          tabBarInactiveTintColor: Colors.inactive,
          tabBarLabelStyle: Fonts.poppinsRegular12,
          headerShown: false,
        })}
      >
        <Tab.Screen
          name={translations[language].inici}
          component={HomeScreen}
          uniqueIdentifier="inici"
        />
        <Tab.Screen
          name={translations[language].troc}
          component={TrocScreen}
          uniqueIdentifier="troc"
        />
        <Tab.Screen
          name={translations[language].buscar}
          component={SearchScreen}
          uniqueIdentifier="buscar"
        />
        <Tab.Screen
          name={translations[language].missatges}
          component={MessagesScreen}
          uniqueIdentifier="missatges"
        />
        <Tab.Screen
          name={translations[language].perfil}
          component={ProfileScreen}
          uniqueIdentifier="perfil"
        />
      </Tab.Navigator>
      {isNeedRegisterVisible && (
        <NeedRegister
          isVisible={isNeedRegisterVisible}
          onClose={() => setIsNeedRegisterVisible(false)}
        />
      )}
    </>
  );
};
