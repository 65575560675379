import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  GiftedChat,
  Bubble,
  InputToolbar,
  Send,
} from "react-native-gifted-chat";
import {
  View,
  Image,
  Platform,
  TouchableOpacity,
  Text,
  Modal,
  TouchableWithoutFeedback,
  StyleSheet,
  KeyboardAvoidingView,
  TextInput,
  Keyboard,
} from "react-native";

import { AppContext } from "../../AppContext";
import SocketIOClient from "socket.io-client";
import profileImages from "../img/profileimage.js";
import { Fonts, Colors } from "../styles/styles.js";
import { useNavigation } from "@react-navigation/native";
import * as Haptic from "expo-haptics";

const translations = {
  ESP: {
    placeholder: "Escribe un mensaje...",
    back: "Volver atrás",
    message: "Este chat está actualmente bloqueado.",
    desbloquear: "Desbloquear",
    atrás: "Atrás",
    back: "Volver",
    send: "Enviar",
    today: "Hoy",
    block:
      "Tienes bloqueado a este usuario, no podréis mandaros mensajes hasta que lo desbloquees.",
  },
  CAT: {
    placeholder: "Escriu un missatge...",
    message: "Aquest xat està actualment bloquejat.",
    desbloquear: "Desbloquejar",
    atrás: "Enrere",
    back: "Tornar",
    send: "Enviar",
    today: "Avui",
    block:
      "Tens aquest usuari bloquejat, no us podreu enviar missatges fins que el desbloquegis.",
  },
  ENG: {
    placeholder: "Write a message...",
    message: "This chat is currently blocked.",
    desbloquear: "Unblock",
    atrás: "Back",
    back: "Back",
    send: "Send",
    today: "Today",
    block:
      "You have this user blocked, you won't be able to send messages until you unblock them.",
  },
};

const ChatScreen = ({ route }) => {
  const { miID, userData, serverAddress, token } = useContext(AppContext);
  const navigation = useNavigation();
  const userId = miID;
  const language = useContext(AppContext).language || "ESP";
  const t = translations[language];
  const chatSocketRef = useRef(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [blockIcon, setBlockIcon] = useState(
    require("../img/icons/noblock.png")
  );
  const [blockerId, setBlockerId] = useState(null);
  const { otherUserId, otherUserPhoto, otherUserName, otherUserSurname } =
    route.params;
  const fullName = otherUserName
    ? `${otherUserName} ${otherUserSurname || ""}`.trim()
    : "";
  const [reload, setReload] = useState(false);
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const closeModal = () => setModalVisible(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false); // Estado para deshabilitar el botón de enviar
  const [inputPlaceholder, setInputPlaceholder] = useState(t.placeholder);

  const renderMessageStatusIcon = (messageStatus) => {
    switch (messageStatus) {
      case "E":
        return "✓"; // Enviado
      case "R":
        return "✓✓"; // Recibido
      case "L":
        return "✓✓"; // Leído
      default:
        return "";
    }
  };
  const handlePress = () => {
    handleBlockToggle();
    setModalVisible(true);
  };

  
  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <TouchableOpacity onPress={() => handleUserPress(otherUserId)}>
          <Text
            style={{ fontWeight: "bold", fontSize: 18, color: Colors.primary }}
          >
            {fullName}
          </Text>
        </TouchableOpacity>
      ),
      headerTitleAlign: "center",
      headerRight: () => (
        <TouchableOpacity onPress={handlePress} style={{ paddingRight: 10 }}>
          <Image source={blockIcon} style={{ width: 30, height: 25 }} />
        </TouchableOpacity>
      ),
      headerLeft: () => (
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={{ paddingLeft: 10 }}
        >
          <Text style={{ fontSize: 16, color: "#007AFF" }}>{t.back}</Text>
        </TouchableOpacity>
      ),
    });
  }, [navigation, fullName, blockIcon, handlePress, otherUserId]);


  const handleBlockToggle = async () => {
    const newBlockState = !isBlocked;
    const response = await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        actuacio: newBlockState ? "BL" : "UNBL",
        usu_Desti: route.params.otherUserId,
        bloquejat: newBlockState ? 1 : 0,
      }),
    });
    const data = await response.json();
    if (data.status === "OK") {
      setIsBlocked(newBlockState);
      setBlockIcon(
        newBlockState
          ? require("../img/icons/block2.png")
          : require("../img/icons/noblock.png")
      );
      if (!newBlockState) {
        cargarMensajesHistoricos(); // Recargar mensajes si se desbloquea
      }
      //navigation.goBack(); // Navega hacia atrás después de cambiar el estado
    }
  };

  const handleUnblock = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "BL",
            usu_Desti: route.params.otherUserId,
            bloquejat: 0,
          }),
        }
      );
      const data = await response.json();
      if (data.status === "OK") {
        setIsBlocked(false);
        setBlockIcon(require("../img/icons/noblock.png"));
        setReload(!reload); // Recargar mensajes históricos después del desbloqueo
        setModalVisible(false); // Cierra el modal después de desbloquear
      } else {
        //console.error("Error al desbloquear:", data.message);
      }
    } catch (error) {
      //console.error("Error al desbloquear:", error);
    }
  };


  const handleBackPress = () => {
    navigation.goBack();
  };
  const handleUserPress = (userId) => {
    navigation.navigate("ProfileOther", { userId: otherUserId });
  };

  const cargarMensajesHistoricos = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ actuacio: "LX", usu_Desti: otherUserId }),
        }
      );
      const data = await response.json();
      if (data.status === "OK" && Array.isArray(data.data)) {
        const mensajesHistoricos = data.data.map((mensaje) => ({
          _id: mensaje.id_missatge,
          text: mensaje.texte,
          createdAt: new Date(mensaje.dataCreacio),
          user: { _id: mensaje.usuariEmisor },
          situacioMissatge: mensaje.situacioMissatge,
        }));
        const groupedMessages = groupMessagesByDate(mensajesHistoricos);
        setMessages(groupedMessages.reverse());
      } else if (data.data && data.data.status === "BLO") {
        setIsBlocked(true);
        setBlockerId(data.data.usu_bloquejador);
        setBlockIcon(require("../img/icons/block2.png"));
        setModalVisible(true);
      }
    } catch (error) {
      //console.error("Error al cargar mensajes históricos:", error);
    }
  };

  const groupMessagesByDate = (messages) => {
    const sortedMessages = messages.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
    const groupedMessages = [];
    let previousDate = "";

    sortedMessages.forEach((message, index) => {
      const messageDate = new Date(message.createdAt);
      const dateString = messageDate.toDateString();

      if (index === 0 || dateString !== previousDate) {
        const dateText = formatDate(messageDate, language);
        groupedMessages.push({
          _id: `date-${message._id}`,
          text: dateText,
          createdAt: messageDate,
          system: true,
        });
      }
      previousDate = dateString;
      groupedMessages.push(message);
    });

    return groupedMessages;
  };

  const formatDate = (date, languageCode = "ESP") => {
    const messageDate = new Date(date);
    messageDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let locale;
    switch (languageCode) {
      case "ESP":
        locale = "es-ES";
        break;
      case "CAT":
        locale = "ca-ES";
        break;
      case "ENG":
        locale = "en-US";
        break;
      default:
        locale = "en-US";
    }

    if (messageDate.getTime() === today.getTime()) {
      return translations[languageCode].today || "Today";
    } else {
      return messageDate.toLocaleDateString(locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  };
  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <TouchableOpacity onPress={() => handleUserPress(otherUserId)}>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 18,
              color: Colors.primary,
              textDecorationLine: "underline", // Añade esta línea para subrayar el texto
            }}
          >
            {fullName}
          </Text>
        </TouchableOpacity>
      ),
    });
  }, [navigation, otherUserId, fullName]);
  useEffect(() => {
    // Configura el título de la pantalla con el nombre y apellido del otro usuario
    const { otherUserName, otherUserSurname } = route.params;
    if (otherUserName && otherUserSurname) {
      navigation.setOptions({ title: `${otherUserName} ${otherUserSurname}` });
    }

    // Inicializa el socket dentro de useEffect y almacena la referencia en chatSocketRef
    const chatSocket = SocketIOClient(serverAddress, {
      path: "/chat-socket",
      query: { token },
    });
    chatSocketRef.current = chatSocket; // Almacenar la referencia al socket

    // Función para cargar mensajes históricos
    const cargarMensajesHistoricos = async () => {
      try {
        const response = await fetch(
          `${serverAddress}/api/v1/perfils/actuacions`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ actuacio: "LX", usu_Desti: otherUserId }),
          }
        );
        const data = await response.json();
        //console.log(data);
        if (data.status === "OK") {
          if (Array.isArray(data.data)) {
            const mensajesHistoricos = data.data.map((mensaje) => ({
              _id: mensaje.id_missatge,
              text: mensaje.texte,
              createdAt: new Date(mensaje.dataCreacio),
              user: { _id: mensaje.usuariEmisor },
              situacioMissatge: mensaje.situacioMissatge,
            }));
            const groupedMessages = groupMessagesByDate(
              mensajesHistoricos,
              language
            );
            setMessages(groupedMessages.reverse());
          } else if (data.data && data.data.status === "BLO") {
            // Maneja el caso donde no hay mensajes pero el chat está bloqueado
            setIsBlocked(true);
            setBlockerId(data.data.usu_bloquejador);
            setBlockIcon(require("../img/icons/block2.png"));
            setModalVisible(true);
          } else {
            // Maneja cualquier otro caso inesperado
            //console.error("Respuesta inesperada:", data.data);
          }
        }
      } catch (error) {
        //console.error("Error al cargar mensajes históricos:", error);
      }
    };

    // Llamada a la función para cargar mensajes históricos
    cargarMensajesHistoricos();

    // Configuración de listeners para el socket
    chatSocket.emit("join chat", { userId: miID, otherUserId });
    chatSocket.on("chat message", (message) =>
      setMessages((previousMessages) =>
        GiftedChat.append(previousMessages, message)
      )
    );

    // Función de limpieza para desconectar y desuscribirse de eventos del socket
    return () => {
      chatSocket.off("chat message");

      chatSocket.emit("leave chat", { userId: miID, otherUserId });
      chatSocket.disconnect();
    };
  }, [
    miID,
    otherUserId,
    serverAddress,
    token,
    navigation,
    route.params,
    language,
    reload,
  ]); // Dependencias

  const onSend = useCallback(
    (messages = []) => {
      setSendButtonDisabled(true); // Deshabilitar el botón al enviar
      setText(""); // Limpiar el input de texto

      messages.forEach((message) => {
        if (chatSocketRef.current) {
          chatSocketRef.current.emit("send message", {
            userId,
            otherUserId: route.params.otherUserId,
            text: message.text,
            _id: message._id,
          });
        } else {
          console.error("Socket no está conectado.");
        }
      });
    },
    [userId, route.params.otherUserId]
  );

  // Maneja el cambio del input para activar o desactivar el botón de enviar
  const handleInputChange = (input) => {
    setText(input);
    setSendButtonDisabled(input.trim() === ""); // Activa el botón solo si hay texto
  };

  const renderSendButton = (props) => (
    <TouchableOpacity
      style={[
        styles.sendButton,
        { backgroundColor: sendButtonDisabled ? "#d3d3d3" : Colors.primary },
      ]}
      onPress={async () => {
        if (!sendButtonDisabled) {
          if (Platform.OS !== "web") {
            await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
          }
          onSend([{ text, user: { _id: miID }, createdAt: new Date() }]);
        }
      }}
      disabled={sendButtonDisabled}
    >
      <Image
        source={require("../img/icons/share3.png")}
        style={styles.sendIcon}
      />
    </TouchableOpacity>
  );
  const renderBubble = (props) => {
    const isMyMessage =
      props.currentMessage.user._id === userData.data[0].id_usuari;
    return (
      <Bubble
        {...props}
        wrapperStyle={{
          left: {
            backgroundColor: Colors.white,
            marginBottom: 4,
            marginLeft: 4,
          },
          right: {
            backgroundColor: "#007aff",
            marginBottom: 4,
            marginRight: 4,
          },
        }}
        renderTicks={(currentMessage) => {
          if (isMyMessage) {
            const iconColor =
              currentMessage.situacioMissatge === "L" ? "#00FF00" : "white";
            const messageStatusIcon = renderMessageStatusIcon(
              currentMessage.situacioMissatge
            );
            return (
              <Text style={{ fontSize: 10, color: iconColor, marginRight: 5 }}>
                {messageStatusIcon}
              </Text>
            );
          }
          return null;
        }}
      />
    );
  };

  const renderMessage = (messageProps) => {
    const { currentMessage, renderBubble } = messageProps;

    let imageSource;
    let additionalStyle = {};

    if (otherUserPhoto) {
      imageSource = { uri: `${serverAddress}/fotos/${otherUserPhoto}` };
    } else {
      imageSource =
        profileImages[otherUserId % 10] ||
        require("../img/profiles/default.png");
      additionalStyle = { backgroundColor: Colors.primary };
    }

    if (currentMessage.system) {
      return (
        <View style={{ alignItems: "center", marginVertical: 5 }}>
          <Text style={{ color: "grey" }}>{currentMessage.text}</Text>
        </View>
      );
    }

    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {currentMessage.user._id !== userData.data[0].id_usuari && (
          <TouchableOpacity onPress={() => handleUserPress(otherUserId)}>
            <Image
              source={imageSource}
              style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                ...additionalStyle,
              }}
            />
          </TouchableOpacity>
        )}
        {renderBubble(messageProps)}
      </View>
    );
  };

  const renderModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => setModalVisible(false)}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          Keyboard.dismiss(); // Oculta el teclado
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>
              {translations[language].message}
            </Text>
            {isBlocked && blockerId === miID ? (
              <>
                <Text style={styles.blockMessageText}>
                  {translations[language].block}
                </Text>
                <View style={styles.buttonContainer}>
                  <TouchableOpacity
                    style={styles.button}
                    onPress={() => {
                      closeModal();
                      navigation.goBack();
                    }}
                  >
                    <Text style={styles.textStyle}>
                      {translations[language].atrás}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.button, styles.unblockButton]}
                    onPress={handleUnblock}
                  >
                    <Text style={styles.textStyle}>
                      {translations[language].desbloquear}
                    </Text>
                  </TouchableOpacity>
                </View>
              </>
            ) : (
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={[styles.button, styles.singleButton]}
                  onPress={() => {
                    closeModal();
                    navigation.goBack();
                  }}
                >
                  <Text style={styles.textStyle}>
                    {translations[language].atrás}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );

  const renderInputToolbar = (props) => (
    <InputToolbar
      {...props}
      containerStyle={styles.inputToolbar}
      primaryStyle={styles.primaryInputContainer}
      renderComposer={(composerProps) => (
        <TextInput
          {...composerProps}
          placeholder={inputPlaceholder}
          placeholderTextColor="#999"
          multiline
          style={styles.textInput}
          value={text}
          onChangeText={handleInputChange}
          blurOnSubmit={false}
        />
      )}
      renderSend={renderSendButton}
    />
  );
  const webStyle = Platform.OS === "web" ? { paddingBottom: 80 } : {};
  const screenStyle = Platform.OS === "ios" ? { marginBottom: 15 } : {};
  
  return (
    // <KeyboardAvoidingView
    //   style={{ flex: 1 }}
    //   behavior={Platform.OS === "ios" ? "padding" : "height"}
    //   keyboardVerticalOffset={90}
    // >
    //   {/* <TouchableWithoutFeedback
    //     onPress={() => {
    //       Keyboard.dismiss(); // Solo cierra el teclado
    //     }}
    //   > */}
        <View style={{ flex: 1, ...screenStyle }}>
          {renderModal()}
          {Platform.OS === "web" && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                padding: 10,
                borderBottomWidth: 1,
                borderBottomColor: "#ddd",
              }}
            >
              <TouchableOpacity
                onPress={handleBackPress}
                style={{ marginLeft: 10 }}
              >
                <Text
                  style={{
                    color: Colors.primary,
                    fontSize: 16,
                    textDecorationLine: "underline",
                  }}
                >
                  {t.back}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => handleUserPress(otherUserId)}
                style={{ flex: 1 }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 16,
                    color: Colors.primary,
                  }}
                >
                  {fullName}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={handlePress}
                style={{ marginRight: 10 }}
              >
                <Image source={blockIcon} style={{ width: 30, height: 25 }} />
              </TouchableOpacity>
            </View>
          )}

          <GiftedChat
            messages={messages}
            onSend={onSend}
            user={{ _id: userData.data[0].id_usuari }}
            renderMessage={renderMessage}
            renderBubble={renderBubble}
            renderInputToolbar={renderInputToolbar}
            placeholder={t.placeholder}
            keyboardShouldPersistTaps="handled"
            isKeyboardInternallyHandled={false}
            minInputToolbarHeight={80}
            alwaysShowSend
            scrollToBottom
            text={text}
            onInputTextChanged={handleInputChange}
            listViewProps={{
              style: {
                paddingBottom: 20, // Ajusta este padding para evitar el solapamiento
              },
              contentContainerStyle: {
                paddingTop: Platform.select({
                  ios: 4, // Padding superior en iOS
                  android: 12,
                  web: 20,
                }),
              },
            }}
          />
        </View>
  );
      // {/* </TouchableWithoutFeedback> */}
  //   </KeyboardAvoidingView>
  // );
};


const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    fontSize: 16,
    color: Colors.primary,
    fontFamily: Fonts.poppinsRegular,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    backgroundColor: Colors.primary,
    flex: 1,
    marginHorizontal: 5,
  },
  singleButton: {
    width: "80%",
  },
  textStyle: {
    color: "white",
    textAlign: "center",
    fontFamily: Fonts.poppinsRegular,
    fontSize: 16,
  },
  blockMessageText: {
    marginTop: 4,
    marginBottom: 24,
    fontSize: 14,
    color: "grey",
    textAlign: "center",
    ...Fonts.poppinsRegular14,
  },
  unblockButton: {
    backgroundColor: "green",
  },
  inputToolbar: {
    borderTopWidth: 0,
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#F7F7F7",
    minHeight: 60,
    marginBottom: 10,
  },
  textInput: {
    flex: 1,
    fontSize: 16,
    color: "#333",
    padding: 10,
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: 40,
    maxHeight: Platform.OS === "ios" ? 60 : 100,
    textAlignVertical: "top",
  },
  primaryInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 25,
    paddingHorizontal: 10,
    marginHorizontal: 8,
    borderWidth: 1,
    borderColor: "#E0E0E0",
  },
  sendButton: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primary,
    borderRadius: 20,
    width: 40,
    height: 40,
    marginHorizontal: -5,
  },
  sendIcon: {
    width: 24,
    height: 24,
    tintColor: "#FFF",
    resizeMode: "contain",
    marginLeft: -2,
  },
});

export default ChatScreen;
