import React, { useContext, useState } from "react";
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TouchableWithoutFeedback,
  Platform,
  Linking,
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";

const ManageSub = ({ isVisible, onClose }) => {
  const { language, token, serverAddress } = useContext(AppContext);
  const [showError, setShowError] = useState(false);

  const createCustomerPortalSession = async () => {
    try {
      setShowError(false); // Ocultar el error al iniciar una nueva solicitud
      const response = await fetch(
        `${serverAddress}/create-customer-portal-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Usa el token directamente desde el contexto
          },
        }
      );

      if (!response.ok) {
        throw new Error("La solicitud a la API falló");
      }

      const result = await response.json(); // Asume que la API responde con JSON que incluye la URL
      // Usar Linking para abrir el URL devuelto por la API
      Linking.openURL(result.url);
    } catch (error) {
      //console.error("Error al crear la sesión del portal del cliente:", error);
      setShowError(true); // Mostrar el error si falla la solicitud
    }
  };

  const getPlatformUrl = () => {
    switch (Platform.OS) {
      case "ios":
        // Este es el enlace que dirige a los usuarios de iOS directamente a la página de sus suscripciones.
        return "https://apps.apple.com/account/subscriptions";
      case "android":
        return "https://play.google.com/store/account/subscriptions";
      default:
        return "https://stripe.com"; // Asumiendo que este es el enlace para gestión de suscripciones web
    }
  };
  

  const getButtonText = () => {
    switch (Platform.OS) {
      case "ios":
        return translations[language].ios;
      case "android":
        return translations[language].android;
      default:
        return translations[language].web;
    }
  };

  const translations = {
    ESP: {
      title:
        "Gestiona tus suscripciones de Trocalia con tu plataforma de pago.",
      ios: "Ir a Apple",
      android: "Ir a Google",
      web: "Ir a Stripe",
      errorStripe:
        "No se encuentra su subscripción de Stripe. Si está seguro que se ha suscrto a Trocalia mediante Stripe, por favor contacte con soporte, sat@trocalia.net.",
    },
    CAT: {
      title:
        "Gestiona les teves subscripcions de Trocalia amb la teva plataforma de pagament.",
      ios: "Anar a Apple",
      android: "Anar a Google",
      web: "Anar a Stripe",
      errorStripe:
        "No es troba la seva subscripció de Stripe. Si està segur que s'ha subscrit a Trocalia mitjançant Stripe, si us plau contacti amb suport, sat@trocalia.net.",
    },
    ENG: {
      title: "Manage your Trocalia subscriptions with your payment platform.",
      ios: "Go to Apple",
      android: "Go to Google",
      web: "Go to Stripe",
      errorStripe:
        "Your Stripe subscription is not found. If you are sure you have subscribed to Trocalia through Stripe, please contact support, , sat@trocalia.net.",
    },
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{translations[language].title}</Text>
            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                if (Platform.OS === "web") {
                  createCustomerPortalSession(); // Llama a la API solo si es web
                } else {
                  Linking.openURL(getPlatformUrl());
                }
              }}
            >
              <Text style={styles.textStyle}>{getButtonText()}</Text>
            </TouchableOpacity>
            {showError && (
              <Text style={styles.errorText}>
                {translations[language].errorStripe}
              </Text>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    ...Fonts.poppinsRegular16,
    color: Colors.primary,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    backgroundColor: Colors.primary,
  },
  textStyle: {
    color: "white",
    textAlign: "center",
    ...Fonts.poppinsRegular16,
  },
});

export default ManageSub;
