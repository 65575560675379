import React, { useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import profileImages from "../img/profileimage.js";

const CardValoracion = ({ valoraciones }) => {
  const { serverAddress, userData } = useContext(AppContext);
  const navigation = useNavigation();

  const navigateToProfile = (usuOrigen) => {
    navigation.navigate("ProfileOther", { userId: usuOrigen });
  };
  
  const getComentarioYValoracion = (valoracion) => {
    if (valoracion.comentari && valoracion.valoracio !== undefined) {
      return { comentari: valoracion.comentari, valoracio: valoracion.valoracio };
    } else if (Array.isArray(valoracion.comentaris) && valoracion.comentaris.length > 0) {
      const firstComentari = valoracion.comentaris[0];
      return {
        comentari: firstComentari.comentari || "Sin comentarios",
        valoracio: firstComentari.valoracio || null
      };
    }
    return { comentari: "Sin comentarios", valoracio: null };
  };

  return (
    <View>
      {(valoraciones || []).map((valoracion, index) => {
        const { comentari, valoracio } = getComentarioYValoracion(valoracion);
        return (
          <TouchableOpacity
            key={index}
            onPress={() => navigateToProfile(valoracion.usu_Origen)}
          >
            <View style={styles.cardContainer}>
              <Image
                style={[
                  styles.profileImage,
                  valoracion.tipusUsuari === "Premium" ? styles.premiumBorder : styles.standardBorder,
                ]}
                resizeMode="cover"
                source={
                  valoracion.foto
                    ? { uri: `${serverAddress}/fotos/${valoracion.foto}` }
                    : profileImages[valoracion.usu_Origen % 10]
                }
              />
              <View style={styles.textContainer}>
                <View style={styles.headerContainer}>
                  <Text style={styles.name}>{`${valoracion.nomUsuari} ${valoracion.cognomUsuari}`}</Text>
                  {valoracio !== null && (
                    <View style={styles.ratingContainer}>
                      <Text style={styles.rating}>{valoracio}</Text>
                      <Image
                        style={styles.ratingIcon}
                        resizeMode="cover"
                        source={require("../img/icons/puntuation.png")}
                      />
                    </View>
                  )}
                </View>
                <Text style={styles.comment} numberOfLines={4}>{comentari}</Text>
              </View>
            </View>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: Colors.grayLighter,
    width: 340,
    marginBottom: 16,
    marginTop: 12,
    borderRadius: 16,
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: Colors.grayLight,
    height: 110,
  },
  profileImage: {
    borderRadius: 35.5,
    width: 71,
    height: 71,
    marginRight: 15,
    backgroundColor: Colors.primary,
  },
  textContainer: {
    flex: 1,
    justifyContent: "space-between",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  name: {
    ...Fonts.dmSerifDisplayRegular16,
  },
  comment: {
    ...Fonts.poppinsRegular14,
  },
  ratingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  rating: {
    ...Fonts.poppinsRegular14,
    marginRight: 5,
    marginBottom: -3, // Bajamos el número 2px
  },
  ratingIcon: {
    width: 16,
    height: 14,
    marginLeft: 0,
  },
  premiumBorder: {
    borderWidth: 2,
    borderColor: Colors.secondary,
  },
  standardBorder: {
    borderWidth: 2,
    borderColor: Colors.primary,
  },
});

export default CardValoracion;
