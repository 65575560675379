import React, { useState, useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Modal,
  Image,
  Platform,
} from "react-native";
import { Colors, Fonts } from "../styles/styles.js";
import * as Haptics from "expo-haptics";
import Pro from "../components/Pro.js";
import { AppContext } from "../../AppContext";
import NeedRegister from "../selectables/NeedRegister.js";

const translate = {
  ESP: {
    ofrece: "Busco quién ofrece",
    necesita: "Busco quién demanda",
  },
  CAT: {
    ofrece: "Busco qui\nofereix",
    necesita: "Busco qui demanda",
  },
  ENG: {
    ofrece: "Looking for offerers",
    necesita: "Looking for seekers",
  },
};

const SelectorOfreceNecesita = ({ onSelect }) => {
  const [selectedButton, setSelectedButton] = useState("ofrece");
  const [proModalVisible, setProModalVisible] = useState(false);
  const { language, userData } = useContext(AppContext);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);

  const closeProModal = () => {
    setProModalVisible(false);
    if (selectedButton !== "ofrece") {
      setSelectedButton("ofrece");
      onSelect("ofrece");
    }
  };

  const renderNeedRegisterModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isNeedRegisterVisible}
        onRequestClose={() => setIsNeedRegisterVisible(false)}
      >
        <NeedRegister onClose={() => setIsNeedRegisterVisible(false)} />
      </Modal>
    );
  };

  const selectButton = (button) => {
    setSelectedButton(button);
    if (Platform.OS !== "web") {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }

    if (button === "necesita") {
      if (userData.data[0].tipusUsuari === "Guest") {
        setIsNeedRegisterVisible(true);
        return;
      }

      if (userData.data[0].tipusUsuari !== "Premium") {
        setProModalVisible(true);
        return;
      }
    }

    onSelect(button);
  };

  return (
    <View style={styles.groupParent}>
      {renderNeedRegisterModal()}
      <Modal
        animationType="slide"
        transparent={true}
        visible={proModalVisible}
        onRequestClose={closeProModal}
      >
        <TouchableOpacity
          style={{ flex: 1 }}
          onPress={closeProModal}
          activeOpacity={1}
        >
          <Pro closeModal={closeProModal} />
        </TouchableOpacity>
      </Modal>

      {/* Botón Ofrece */}
      <TouchableOpacity
        onPress={() => selectButton("ofrece")}
        style={[
          styles.button,
          selectedButton === "ofrece" ? styles.selected : styles.unselected,
        ]}
      >
        <Text style={[styles.buttonText, Fonts.poppinsRegular14]}>
          {translate[language].ofrece}
        </Text>
        <Image
          style={styles.icons}
          source={require("../img/icons/ofrece.png")}
        />
      </TouchableOpacity>

      {/* Botón Necesita */}
      <TouchableOpacity
        onPress={() => selectButton("necesita")}
        style={[
          styles.button,
          selectedButton === "necesita" ? styles.selected : styles.unselected,
        ]}
      >
        <View style={styles.buttonTextContainer}>
          <Text style={[styles.buttonText, Fonts.poppinsRegular14]}>
            {translate[language].necesita}
          </Text>
          <Text style={styles.proText}>PRO</Text>
        </View>
        <Image
          style={styles.icons}
          source={require("../img/icons/necesita.png")}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  groupParent: {
    flexDirection: "row",
    width: "100%",
    height: 70,
    marginTop: 14,
    marginBottom: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderRadius: 15,
    backgroundColor: Colors.white,
    width: 150,
    height: 70,
    margin: 10,
    ...Platform.select({
      ios: {
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.05,
        shadowRadius: 3,
      },
      android: {
        elevation: 3,
      },
      web: {
        boxShadow: "0px 2px 3px rgba(0,0,0,0.05)",
      },
    }),
  },
  buttonText: {
    color: Colors.black,
    lineHeight: 20,
    textAlign: "left",
  },
  proText: {
    color: Colors.secondary,
    fontSize: 10,
    textAlign: "left",
    marginLeft: 5,
    ...Platform.select({
      web: {
        marginLeft: 2,
        transform: [{ translateX: -25 }],
      },
    }),
  },
  buttonTextContainer: {
    flexDirection: "row",
    alignItems: "center",
    ...Platform.select({
      web: {
        flexWrap: "nowrap",
        maxWidth: "100%",
        overflow: "hidden",
      },
    }),
  },
  icons: {
    right: 10,
    width: 15,
    height: 26,
    position: "absolute",
  },
  selected: {
    opacity: 1,
    borderColor: Colors.primary,
    borderWidth: 2,
  },
  unselected: {
    opacity: 0.9,
    borderColor: "rgba(0, 0, 0, 0.19)",
    borderWidth: 1,
  },
});

export default SelectorOfreceNecesita;
