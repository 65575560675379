import React, { useContext, useMemo, useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Platform,
  Modal,
  ScrollView,
  Alert,
  Linking,
} from "react-native";
import { Colors, Fonts } from "../styles/styles";
import { NOTIFICATION_MESSAGESID } from "../db/NotificationMessages";
import { AppContext } from "../../AppContext";
import { useNavigation } from "@react-navigation/native";
import profileImages from "../img/profileimage.js";
import { BlurView } from "expo-blur";
import Pro from "../components/Pro.js";
import * as Notifications from "expo-notifications";
import { ExpoPushContext } from "../../ExpoPushContext.js"; 
const translate = {
  ESP: {
    year: "años",
    month: "meses",
    day: "días",
    hour: "horas",
    minute: "minutos",
    second: "segundos",
    since: "hace",
    standard: "Un usuario cercano",
    notificationTitle: "Notificación de Trocalia",
    activateNotifications:
      "Activa si quieres que te avisemos de las novedades que hay en ",
    alertTitle: "Permite que Trocalia te envíe notificaciones",
    alertMessage: "Toca configuración para actualizar tus preferencias.",
    noOption: "Ahora no",
    settingsOption: "Configuración",
  },
  CAT: {
    year: "anys",
    month: "mesos",
    day: "dies",
    hour: "hores",
    minute: "minuts",
    second: "segons",
    since: "fa",
    standard: "Un usuari proper",
    notificationTitle: "Notificació de Trocalia",
    activateNotifications:
      "Activa si vols que t'avisem de les novetats que hi ha al ",
    alertTitle: "Permet que Trocalia t'envii notificacions",
    alertMessage: "Toca configuració per actualitzar les teves preferències.",
    noOption: "Ara no",
    settingsOption: "Configuració",
  },
  ENG: {
    year: "years",
    month: "months",
    day: "days",
    hour: "hours",
    minute: "minutes",
    second: "seconds",
    since: "since",
    standard: "A nearby user",
    notificationTitle: "Notification from Trocalia",
    activateNotifications:
      "Enable if you want us to notify you about the news in ",
    alertTitle: "Allow Trocalia to send you notifications",
    alertMessage: "Tap settings to update your preferences.",
    noOption: "Not now",
    settingsOption: "Settings",
  },
};

const NotificationList = ({ notifications }) => {
  const { language, serverAddress, userData } = useContext(AppContext);
  const navigation = useNavigation();
  const isPremium =
    userData && userData.data && userData.data[0].tipusUsuari === "Premium";
  const [proModalVisible, setProModalVisible] = useState(false);
  const [isNotificationsGranted, setIsNotificationsGranted] = useState(null); // Estado para verificar permisos de notificación
  const zona = userData ? userData.data[0].Id_zona : ""; // Obtener el nombre del barrio

  useEffect(() => {
    // Verifica el estado de los permisos de notificación al montar el componente
    const checkNotificationPermissions = async () => {
      const { status } = await Notifications.getPermissionsAsync();
      setIsNotificationsGranted(status === "granted");
    };

    checkNotificationPermissions();
  }, []);

  const requestPushPermissions = async () => {
    // Solicita permisos para las notificaciones push
    const { status } = await Notifications.requestPermissionsAsync();
    setIsNotificationsGranted(status === "granted");

    // Si los permisos no están concedidos, mostrar alert con opciones
    if (status !== "granted") {
      Alert.alert(
        translate[language].alertTitle, // Título del alert
        translate[language].alertMessage, // Mensaje del alert
        [
          {
            text: translate[language].noOption, // Opción "Ahora no"
            style: "cancel",
          },
          {
            text: translate[language].settingsOption, // Opción "Configuración"
            onPress: () => {
              // Abrir la configuración de la app
              if (Platform.OS === "ios") {
                Linking.openURL("app-settings:");
              } else {
                Linking.openSettings();
              }
            },
          },
        ],
        { cancelable: true }
      );
    }
  };

  const renderUserInfo = (notification, index, hvtpIndex) => {
    const isCustomNotification = notification.codi === "NOTITXT";
    if (isCustomNotification) {
      const notificationTitle = translate[language].notificationTitle;
      return <Text style={styles.userName}>{notificationTitle}</Text>;
    } else {
      const isHVTP = notification.codi === "HVTP";
      const shouldUseStandardText =
        isHVTP && !isPremium && !shouldDisplayHVTP(hvtpIndex);
      const standardText = translate[language].standard;
      const name = `${notification.body.nomUsuari} ${notification.body.cognomUsuari}`;

      return (
        <Text style={styles.userName}>
          {shouldUseStandardText ? standardText : name}
        </Text>
      );
    }
  };

  const shouldDisplayHVTP = (hvtpIndex) => {
    return hvtpIndex === 0 || (hvtpIndex + 1) % 4 === 0;
  };

  const getProfileImageSource = (notification) => {
    const { foto, usu_Origen } = notification.body;
    if (foto) {
      return { uri: `${serverAddress}/fotos/${foto}` };
    } else {
      return (
        profileImages[usu_Origen % 10] || require("../img/profiles/default.png")
      );
    }
  };

  const renderNotificationMessage = (notification) => {
    if (notification.codi === "NOTITXT" && notification.body.text) {
      return notification.body.text;
    } else {
      return (
        NOTIFICATION_MESSAGESID[notification.codi][language] ||
        "Notificación desconocida"
      );
    }
  };

  const handlePressNotification = (notification, index, hvtpIndex) => {
    if (
      notification.codi === "HVTP" &&
      !isPremium &&
      !shouldDisplayHVTP(hvtpIndex)
    ) {
      setProModalVisible(true);
    } else {
      navigation.navigate("ProfileOther", {
        userId: notification.body.id_usuari,
      });
    }
  };

  const renderImage = (notification, index, hvtpIndex) => {
    const imageSource = getProfileImageSource(notification);
    const isHVTP = notification.codi === "HVTP";

    if (isHVTP && !isPremium && !shouldDisplayHVTP(hvtpIndex)) {
      return (
        <View style={styles.imageContainer}>
          <Image style={styles.image} source={imageSource} />
          <BlurView
            style={StyleSheet.absoluteFill}
            intensity={Platform.OS === "ios" ? 10 : 25}
            tint="light"
            experimentalBlurMethod="dimezisBlurView"
          />
        </View>
      );
    } else {
      return <Image style={styles.image} source={imageSource} />;
    }
  };

  const parseDate = (dateStr) => {
    return new Date(dateStr);
  };

  const getTimeSince = (dateStr) => {
    const date = parseDate(dateStr);
    const seconds = Math.floor((new Date() - date) / 1000);

    const { year, month, day, hour, minute, second, since } =
      translate[language];

    let interval = seconds / 31536000;
    if (interval > 1) return `${since} ${Math.floor(interval)} ${year}`;

    interval = seconds / 2592000;
    if (interval > 1) return `${since} ${Math.floor(interval)} ${month}`;

    interval = seconds / 86400;
    if (interval > 1) return `${since} ${Math.floor(interval)} ${day}`;

    interval = seconds / 3600;
    if (interval > 1) return `${since} ${Math.floor(interval)} ${hour}`;

    interval = seconds / 60;
    if (interval > 1) return `${since} ${Math.floor(interval)} ${minute}`;

    return `${since} ${Math.floor(seconds)} ${second}`;
  };

  const sortedNotifications = useMemo(() => {
    return notifications.sort(
      (a, b) => parseDate(b.createdAt) - parseDate(a.createdAt)
    );
  }, [notifications]);

  return (
    <ScrollView contentContainerStyle={styles.frameScreen}>
      {/* Botón para activar notificaciones si no están activadas */}
      {isNotificationsGranted === false && Platform.OS !== "web" && (
        <TouchableOpacity
          onPress={requestPushPermissions}
          style={styles.notificationPrompt}
        >
          <Text style={styles.notificationText}>
            {translate[language].activateNotifications + zona}
          </Text>
          <Text style={styles.iconText}>🔔</Text>
        </TouchableOpacity>
      )}

      {sortedNotifications.map((notification, index) => {
        const key = `notification-${notification._id}-${index}`;
        const timeSince = getTimeSince(notification.createdAt);
        const hvtpIndex = sortedNotifications
          .filter((n) => n.codi === "HVTP")
          .indexOf(notification);

        if (notification.codi === "STROC") {
          const message = renderNotificationMessage(notification);
          return (
            <TouchableOpacity
              key={key}
              style={[
                styles.notificationWrapper,
                styles.specialNotificationWrapper,
              ]}
              onPress={() => navigation.navigate("Troc")}
            >
              <View style={[styles.textContainer, styles.specialTextContainer]}>
                <Text style={styles.specialNotificationMessage}>{message}</Text>
              </View>
              <Text style={styles.timeSince}>{timeSince}</Text>
            </TouchableOpacity>
          );
        }

        return (
          <TouchableOpacity
            key={key}
            style={styles.notificationWrapper}
            onPress={() =>
              handlePressNotification(notification, index, hvtpIndex)
            }
          >
            {renderImage(notification, index, hvtpIndex)}
            <View style={styles.textContainer}>
              {renderUserInfo(notification, index, hvtpIndex)}
              <Text style={styles.notificationMessage}>
                {renderNotificationMessage(notification)}
              </Text>
            </View>
            <Text style={styles.timeSince}>{timeSince}</Text>
          </TouchableOpacity>
        );
      })}

      <Modal
        animationType="slide"
        transparent={true}
        visible={proModalVisible}
        onRequestClose={() => setProModalVisible(false)}
      >
        <Pro closeModal={() => setProModalVisible(false)} />
      </Modal>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  frameScreen: {
    backgroundColor: "transparent",
    alignItems: "center",
    width: 340,
    marginTop: 4,
    marginBottom: 4,
  },
  notificationPrompt: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Colors.white,
    width: 340,
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 16,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: Colors.primary,
  },
  iconText: {
    fontSize: 20,
    color: Colors.primary,
  },
  notificationText: {
    color: Colors.primary,
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
    flex: 1,
  },
  notificationWrapper: {
    flexDirection: "row",
    backgroundColor: Colors.grayLighter,
    borderRadius: 16,
    marginBottom: 16,
    padding: 16,
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: 80,
    position: "relative",
    width: "100%",
    borderWidth: 1,
    borderColor: Colors.grayLight,
  },
  imageContainer: {
    width: 45,
    height: 45,
    borderRadius: 30,
    overflow: "hidden",
    marginRight: 12,
  },
  image: {
    width: 45,
    height: 45,
    borderRadius: 30,
    marginRight: 12,
    backgroundColor: Colors.primary,
  },
  textContainer: {
    flex: 1,
  },
  userName: {
    ...Fonts.dmSerifDisplayRegular16,
    color: Colors.grey,
    marginBottom: 4,
  },
  notificationMessage: {
    ...Fonts.poppinsRegular14,
    color: Colors.darkGrey,
  },
  timeSince: {
    position: "absolute",
    top: 4,
    right: 6,
    fontSize: 12,
    color: Colors.darkGrey,
  },
  specialNotificationWrapper: {
    backgroundColor: Colors.primary,
  },
  specialNotificationMessage: {
    ...Fonts.poppinsMedium16,
    color: Colors.white,
    textAlign: "center",
  },
});

export default NotificationList;
