import React, { useState, useContext, useEffect, useRef } from 'react';
import { View, Text, StyleSheet, Image, Dimensions, ScrollView, TouchableOpacity, Linking } from 'react-native';
import { AppContext } from '../../AppContext';
import { Colors, Fonts } from '../styles/styles';
import { useNavigation } from "@react-navigation/native";
import { Animated } from 'react-native';

const { width } = Dimensions.get('window');

const translations = {
  ESP: {
    contact: 'Contacto',
    anuncia: 'Anúnciate',
    sat: 'Soporte',
    condiciones: 'Condiciones de uso',
    privacidad: 'Política de privacidad',
    faqs: 'FAQs',
  },
  CAT: {
    contact: 'Contacte',
    anuncia: 'Anuncia\'t',
    sat: 'Suport',
    condiciones: 'Condicions d\'ús',
    privacidad: 'Política de privacitat',
    faqs: 'FAQs',
  },
  ENG: {
    contact: 'Contact',
    anuncia: 'Advertise',
    sat: 'Support',
    condiciones: 'Terms of use',
    privacidad: 'Privacy policy',
    faqs: 'FAQs',
  },
  // Puedes agregar más traducciones aquí
};

const Footer = ({ language }) => {
  const t = translations[language]; 
  const navigation = useNavigation();

  return (
    <View style={styles.footer}>
      <View style={styles.footerLinks}>
      <Text style={styles.footerText}>Trocalia Digital S.L</Text>
      <TouchableOpacity onPress={() => Linking.openURL('mailto:info@trocalia.net')}>
        <Text style={styles.footerLink}>{t.contact}</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => Linking.openURL('mailto:anunciant@trocalia.net')}>
        <Text style={styles.footerLink}>{t.anuncia}</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => Linking.openURL('mailto:sat@trocalia.net')}>
        <Text style={styles.footerLink}>{t.sat}</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => navigation.navigate('Terms')}>
        <Text style={styles.footerLink}>{t.condiciones}</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => navigation.navigate('Privacy')}>
        <Text style={styles.footerLink}>{t.privacidad}</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => navigation.navigate('Faq')}>
        <Text style={styles.footerLink}>{t.faqs}</Text>
      </TouchableOpacity>
      </View>
      <Image 
        source={require('../img/logo.png')} 
        style={styles.footerLogo} 
      />
      {/* Puedes agregar más opciones aquí */}
    </View>
  );
};

const Explain = () => {
  const { language } = useContext(AppContext); // Obtiene el idioma desde el contexto

  const sections = {
    ESP: [
      {
        key: 'que',
        title: "Descubre tu zona",
        description: "Puedes ofrecer y buscar servicios y objetos, así como intercambiarlos con otros usuarios cercanos.",
        logo: require('../img/icons/explain1.png'),
      },
      {
        key: 'registro',
        title: "Particulares y empresas",
        description: "Encontrarás todo tipo de servicios y objetos, tanto de particulares como de empresas.",
        logo: require('../img/icons/explain2.png'),
      },
      {
        key: 'seguridad',
        title: "Fiable y privado",
        description: "Es un entorno seguro donde todos los vecinos están verificados. Tus datos son privados y no se comparten con nadie.",
        logo: require('../img/icons/explain3.png'),
      },
    ],
    CAT: [
      {
        key: 'que',
        title: "Descobreix la teva zona",
        description: "Pots oferir i buscar serveis i objectes, així com intercanviar-los amb altres usuaris propers.",
        logo: require('../img/icons/explain1.png'),
      },
      {
        key: 'registro',
        title: "Particulars i empreses",
        description: "Trobaràs tot tipus de serveis i objectes, tant de particulars com d'empreses.",
        logo: require('../img/icons/explain2.png'),
      },
      {
        key: 'seguridad',
        title: "Fiable i privat",
        description: "És un entorn segur on tots els veïns estan verificats. Les teves dades són privades i no es comparteixen amb ningú.",
        logo: require('../img/icons/explain3.png'),
      },
    ],
    ENG: [
      {
        key: 'que',
        title: "Discover your area",
        description: "You can offer and search for services and items, as well as exchange them with other nearby users.",
        logo: require('../img/icons/explain1.png'),
      },
      {
        key: 'registro',
        title: "Individuals and Businesses",
        description: "You will find all kinds of services and items, both from individuals and businesses.",
        logo: require('../img/icons/explain2.png'),
      },
      {
        key: 'seguridad',
        title: "Reliable and Private",
        description: "It's a secure environment where all neighbors are verified. Your data is private and not shared with anyone.",
        logo: require('../img/icons/explain3.png'),
      },
    ],
    // Puedes agregar más secciones aquí para cada idioma
  };
  const sectionsData = sections[language] || sections.ESP;
  const fadeAnims = sectionsData.map(() => useRef(new Animated.Value(0)).current);

  useEffect(() => {
    const animate = () => {
      const animations = fadeAnims.map((anim, index) =>
        Animated.timing(anim, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true,
          delay: index * 1000
        })
      );
      Animated.stagger(500, animations).start();
    };

    animate();
  }, []);

  return (
    <View style={styles.complet}>
      {sectionsData.map((section, index) => (
        <Animated.View 
          key={section.key} 
          style={[
            styles.section, 
            { opacity: fadeAnims[index] }
          ]}
        >
          <View style={styles.content}>
            <Text style={styles.header}>{section.title}</Text>
            <Text style={styles.description}>{section.description}</Text>
            <Image source={section.logo} style={styles.icon} />
          </View>
        </Animated.View>
      ))}
      <Footer language={language} />
    </View>
  );
};



const styles = StyleSheet.create({
  section: {
    width: width,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    marginBottom: -30,
  },
  content: {
    width: '95%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.grayLighter,
    borderRadius: 10,
    padding: 20,
    marginTop: 80,
  },
  header: {
    ...Fonts.poppinsMedium18,
    marginBottom: 10,
    textAlign: 'center',
  },
  description: {
    ...Fonts.poppinsRegular16,
    textAlign: 'center',
    marginBottom: 20,
  },
  icon: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
  },
  complet: {
    marginBottom: 0,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.primary,
    padding: 20,
    marginTop: 130,
  },
  footerText: {
    color: 'white',
    ...Fonts.poppinsMedium18,
    marginBottom: 10,
    
  },
  footerLink: {
    color: 'white',
    ...Fonts.poppinsRegular16,
    textDecorationLine: 'underline',
    marginBottom: 10,
  },
  footerLogo: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
  },
});

export default Explain;