import React, { useState, useContext } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Platform,
  Dimensions,
} from "react-native";
import { Colors, Fonts } from "../styles/styles";
import trocaliaIcon from "../img/icons/trocalia.png";
import Svg, { Path } from "react-native-svg";
import { AppContext } from "../../AppContext";
import * as SecureStore from "expo-secure-store";

// Objeto de traducción para los diferentes idiomas
const translations = {
  ESP: {
    steps: [
      {
        title: "Explora tu Barrio",
        subtitle: "Descubre los **servicios** y **objetos** que se ofrecen y demandan en tu comunidad.",
        image: require("../img/onboarding/1.png"),
      },
      {
        title: "Añade lo que Ofreces o Demandas",
        subtitle: "Servicios u **objetos**, **ventas**, **regalos**, **alquileres** o **préstamos**. ¡Todo tiene su lugar!",
        image: require("../img/onboarding/2.png"),
      },
      {
        title: "Haz Trocs con Otros Usuarios",
        subtitle: "Chatea con usuarios que coincidan (**match**) con tus **ofertas** o **demandas**.",
        image: require("../img/onboarding/3.png"),
      },
      {
        title: "Activa Notificaciones",
        subtitle: "Mantente al tanto de los **mensajes** y **coincidencias** (Trocs) para no perder ninguna oportunidad.",
        image: require("../img/onboarding/4.png"),
      },
    ],
    buttonText: "Explorar",
  },
  CAT: {
    steps: [
      {
        title: "Explora el teu Barri",
        subtitle: "Descobreix els **serveis** i **objectes** que s'ofereixen i es demanen a la teva comunitat.",
        image: require("../img/onboarding/1.png"),
      },
      {
        title: "Afegeix el que Ofereixes o Demanes",
        subtitle: "**Serveis** o **objectes**, **vendes**, **regals**, **lloguers** o **préstecs**. Tot té el seu lloc!",
        image: require("../img/onboarding/2.png"),
      },
      {
        title: "Fes Trocs amb Altres Usuaris",
        subtitle: "Xateja amb usuaris que coincideixin (**match**) amb les teves **ofertes** o **demandes**.",
        image: require("../img/onboarding/3.png"),
      },
      {
        title: "Activa Notificacions",
        subtitle: "Mantén-te al dia dels **missatges** i **coincidències** (Trocs) per no perdre cap oportunitat.",
        image: require("../img/onboarding/4.png"),
      },
    ],
    buttonText: "Explorar",
  },
  ENG: {
    steps: [
      {
        title: "Explore Your Neighborhood",
        subtitle: "Discover the **services** and **items** being offered and requested in your community.",
        image: require("../img/onboarding/1.png"),
      },
      {
        title: "Add What You Offer or Need",
        subtitle: "**Services** or **items**, **sales**, **gifts**, **rentals**, or **loans**. Everything has its place!",
        image: require("../img/onboarding/2.png"),
      },
      {
        title: "Make Trocs with Other Users",
        subtitle: "Chat with users who **match** your **offers** or **needs**.",
        image: require("../img/onboarding/3.png"),
      },
      {
        title: "Activate Notifications",
        subtitle: "Stay updated on **messages** and **matches** (Trocs) to never miss an opportunity.",
        image: require("../img/onboarding/4.png"),
      },
    ],
    buttonText: "Explore",
  },
};

const OnboardingScreen = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigation = useNavigation();
  const { width } = Dimensions.get("window");
  const { language } = useContext(AppContext);
  const onboardingData = translations[language].steps;
  const buttonText = translations[language].buttonText;
  const { title, subtitle, image } = onboardingData[currentStep];

  const handleOnboardingComplete = async () => {
    if (Platform.OS === "web") {
      localStorage.setItem("hasSeenOnboarding", "true");
    } else {
      await SecureStore.setItemAsync("hasSeenOnboarding", "true");
    }
    navigation.navigate("Register");
  };

  const handleNext = () => {
    if (currentStep < onboardingData.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleOnboardingComplete();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const dynamicStyles = {
    image: {
      width: "100%",
      height: Platform.OS === "web" ? 300 : width * 0.8,
    },
    textContainer: {
      marginTop: Platform.OS === "web" ? 20 : width * 0.1,
    },
  };

  return (
    <ScrollView contentContainerStyle={styles.scrollView}>
      <View style={styles.blueHeader}>
        <Svg
          height="100%"
          width="100%"
          viewBox="0 0 375 180"
          preserveAspectRatio="none"
        >
          <Path
            d={`M0,0 L375,0 L375,160 C375,160 187.5,200 0,160 Z`}
            fill={Colors.primary}
          />
        </Svg>
        <Image style={styles.logo} resizeMode="cover" source={trocaliaIcon} />
      </View>
      <View style={styles.onboarding}>
        <Image
          style={dynamicStyles.image}
          source={image}
          resizeMode="contain"
        />
        <View style={[styles.textContainer, dynamicStyles.textContainer]}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.subtitle}>
            {subtitle.split("**").map((text, index) =>
              index % 2 === 1 ? (
                <Text key={index} style={styles.boldText}>
                  {text}
                </Text>
              ) : (
                text
              )
            )}
          </Text>
        </View>
        <TouchableOpacity onPress={handleNext} style={styles.nextButton}>
          <Image
            style={styles.nextIcon}
            source={require("../img/icons/nextwhite.png")}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={handleOnboardingComplete}
          style={styles.skipButton}
        >
          <Text style={styles.skip}>{buttonText}</Text>
        </TouchableOpacity>
        {currentStep > 0 && (
          <TouchableOpacity onPress={handleBack} style={styles.backButton}>
            <Image
              style={styles.backIcon}
              source={require("../img/icons/back.png")}
            />
          </TouchableOpacity>
        )}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    backgroundColor: Colors.white,
  },
  blueHeader: {
    width: "100%",
    height: 130,
    backgroundColor: Colors.primary,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    marginTop: Platform.OS === "android" ? -16 : 0,
  },
  logo: {
    width: 240,
    height: 50,
    position: "absolute",
    top: 65,
  },
  onboarding: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    alignItems: "center",
    paddingHorizontal: 20,
  },
  title: {
    ...Fonts.poppinsSemiBold24,
    color: Colors.grayDark,
    textAlign: "center",
    marginBottom: 20,
  },
  subtitle: {
    ...Fonts.poppinsRegular16,
    color: Colors.grayDark,
    textAlign: "center",
  },
  boldText: {
    ...Fonts.poppinsSemiBold16,
    color: Colors.grayDark,
  },
  nextButton: {
    position: "absolute",
    bottom: 20,
    right: 20,
  },
  nextIcon: {
    height: 42,
    width: 42,
  },
  skipButton: {
    position: "absolute",
    bottom: 30,
    left: 20,
  },
  skip: {
    ...Fonts.poppinsRegular16,
    color: Colors.grayMedium,
  },
  backButton: {
    position: "absolute",
    top: 20,
    left: 20,
  },
  backIcon: {
    height: 20,
    width: 20,
  },
});

export default OnboardingScreen;

