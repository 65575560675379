import React, { useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import { Colors, Fonts } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import { useNavigation } from "@react-navigation/native";
import profileImages from "../img/profileimage.js";
import favIcon from "../img/icons/favon.png";

const translate = {
  ESP: {
    valoration: "valoraciones",
  },
  CAT: {
    valoration: "valoracions",
  },
  ENG: {
    valoration: "ratings",
  },
};

const CardFavorites = ({ item }) => {
  const navigation = useNavigation();
  const {
    id_usuari,
    nomUsuari,
    cognomUsuari,
    presentacio,
    foto,
    Valoracio,
    numComentaris,
    tipusUsuari,
    distancia,
  } = item;
  const { serverAddress, language } = useContext(AppContext);

  let imageUrl;
  if (foto) {
    imageUrl = { uri: `${serverAddress}/fotos/${foto}` };
  } else {
    imageUrl =
      profileImages[id_usuari % 10] || require("../img/profiles/default.png");
  }

  const goToProfile = () => {
    navigation.navigate("ProfileOther", { userId: id_usuari });
  };

  // Comprobar y formatear Valoracio
  const formattedValoracio = !isNaN(parseFloat(Valoracio))
    ? parseFloat(Valoracio).toFixed(1)
    : "-";

  const formattedNumComentaris = numComentaris || "0";
  const formattedDistancia = distancia ? `${parseFloat(distancia).toFixed(1)} Km` : "";

  return (
    <TouchableOpacity onPress={goToProfile}>
      <View style={styles.cardContainer}>
        <Image
          style={[
            styles.instanceItem,
            tipusUsuari === "Premium" ? styles.premiumBorder : styles.standardBorder,
            !foto ? styles.defaultImageBackground : {},
          ]}
          resizeMode="cover"
          source={imageUrl}
        />
        {tipusUsuari === "Premium" && (
          <Text style={styles.proText}>PRO</Text>
        )}
        <View style={styles.textContainer}>
          <View style={styles.titleRow}>
            <Text style={[Fonts.dmSerifDisplayRegular16, styles.title]}>
              {nomUsuari} {cognomUsuari}
            </Text>
          </View>
          <Text
            style={[Fonts.poppinsRegular14, styles.descriptionText]}
            numberOfLines={2}
          >
            {presentacio}
          </Text>
          <View style={styles.ratingRow}>
            <Image
              style={styles.ratingIcon}
              resizeMode="cover"
              source={require("../img/icons/puntuation.png")}
            />
            <Text style={Fonts.poppinsRegular12}>{formattedValoracio}</Text>
            <View style={styles.separator} />
            <Text style={[Fonts.poppinsRegular12, styles.valoracionsText]}>
              {formattedNumComentaris} {translate[language].valoration}
            </Text>
            {formattedDistancia && (
              <>
                <View style={styles.separator} />
                <Text style={[Fonts.poppinsRegular12, styles.kmText]}>
                  {formattedDistancia}
                </Text>
              </>
            )}
          </View>
        </View>
        <Image source={favIcon} style={styles.favIcon} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "row",
    marginTop: 14,
    marginBottom: 4,
    alignItems: "center",
    backgroundColor: Colors.grayLighter,
    borderRadius: 16,
    height: 115,
    justifyContent: "flex-start",
    padding: 16,
    width: 340,
    alignSelf: "center",
    borderWidth: 1,
    borderColor: Colors.grayLight,
  },
  instanceItem: {
    borderRadius: 40,
    width: 71,
    height: 71,
  },
  textContainer: {
    marginLeft: 16,
    flex: 1,
    justifyContent: "space-between",
  },
  titleRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  title: {
    marginBottom: 2,
  },
  descriptionText: {
    marginTop: 6,
  },
  ratingRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 12,
  },
  valoracionsText: {
    marginLeft: 5,
  },
  separator: {
    width: 1,
    height: 12,
    backgroundColor: Colors.black,
    marginHorizontal: 5,
  },
  ratingIcon: {
    width: 16,
    height: 14,
    marginRight: 2,
    marginTop: -3,
  },
  favIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    width: 24,
    height: 24,
  },
  premiumBorder: {
    borderWidth: 2,
    borderColor: Colors.secondary,
  },
  standardBorder: {
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  defaultImageBackground: {
    backgroundColor: Colors.primary,
  },
  proText: {
    position: "absolute",
    top: 4,
    left: 4,
    color: Colors.secondary,
    ...Fonts.poppinsSemiBold16,
  },
  kmText: {
    marginLeft: 5,
  },
});

export default CardFavorites;
