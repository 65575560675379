import React, { useContext, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  Modal,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Colors, Fonts } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import profileImages from "../img/profileimage.js";
import Pro from "../components/Pro.js";
import NeedRegister from "../selectables/NeedRegister.js";

const translations = {
  ESP: {
    venta: "Venta",
    alquiler: "Alquiler",
    prestamo: "Préstamo",
    regalo: "Regalo",
    compra: "Compra",
    prestado: "Prestado",
    regalado: "Regalado",
    servicio: "Servicio",
    promoPart1: "¿Quieres aparecer destacado?",
    promoPart2: "Hazte PRO",
  },
  CAT: {
    venta: "Venda",
    alquiler: "Lloguer",
    prestamo: "Préstec",
    regalo: "Regal",
    compra: "Compra",
    prestado: "Prestat",
    regalado: "Regalat",
    servicio: "Servei",
    promoPart1: "Vols aparèixer destacat?",
    promoPart2: "Fes-te PRO",
  },
  ENG: {
    venta: "Sale",
    alquiler: "Rent",
    prestamo: "Loan",
    regalo: "Gift",
    compra: "Purchase",
    prestado: "Borrowed",
    regalado: "Given away",
    servicio: "Service",
    promoPart1: "Do you want to stand out?",
    promoPart2: "Become PRO",
  },
};

const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const getTimeDifference = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);
  const diffMs = now - date;
  const diffMins = Math.round(diffMs / (1000 * 60));
  const diffHours = Math.round(diffMins / 60);
  const diffDays = Math.round(diffHours / 24);
  const diffMonths = Math.round(diffDays / 30);
  const diffYears = Math.round(diffDays / 365);

  if (diffMins < 60) return `${diffMins}m`;
  if (diffHours < 24) return `${diffHours}h`;
  if (diffDays < 30) return `${diffDays}d`;
  if (diffMonths < 12) return `${diffMonths}M`;
  return `${diffYears}A`;
};

const CardSVertical = ({ data, isPromotionCard }) => {
  const navigation = useNavigation();
  const { serverAddress, language, userData } = useContext(AppContext);
  const [isProModalVisible, setIsProModalVisible] = useState(false);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);

  const handlePress = () => {
    navigation.navigate("ProfileOther", { userId: data.id_usuari });
  };

  const renderNeedRegisterModal = () => {
    if (isNeedRegisterVisible) {
      return (
        <NeedRegister
          isVisible={isNeedRegisterVisible}
          onClose={() => setIsNeedRegisterVisible(false)}
        />
      );
    }
    return null;
  };

  const handlePressPromotion = () => {
    if (userData?.data[0]?.tipusUsuari !== "Premium") {
      setIsProModalVisible(true);
    } else {
      alert("User is already a PRO");
    }
  };

  if (isPromotionCard) {
    return (
      <View>
        <TouchableOpacity onPress={handlePressPromotion}>
          <View style={[styles.cardContainer, styles.promotionCard]}>
            <Text style={styles.promotionCardTextPart1}>
              {translations[language].promoPart1}
            </Text>
            <Text style={styles.promotionCardTextPart2}>
              {translations[language].promoPart2}
            </Text>
          </View>
        </TouchableOpacity>
        <Modal
          animationType="slide"
          transparent={false}
          visible={isProModalVisible}
          onRequestClose={() => setIsProModalVisible(false)}
        >
          <Pro closeModal={() => setIsProModalVisible(false)} />
        </Modal>
      </View>
    );
  }

  if (!data) {
    return null;
  }

  const isOfferOrDemand =
    data.id_Oferta !== undefined || data.id_demanda !== undefined;

  const roundedDistancia = data.distancia ? Math.round(data.distancia) : null;
  const roundedValoracio = data.valoracio
    ? Math.round(data.valoracio * 10) / 10
    : "-";

  const imageUrl = isOfferOrDemand
    ? data.fotoOferta
      ? `${serverAddress}/fotos/${data.fotoOferta}`
      : data.foto || data.Foto
      ? `${serverAddress}/fotos/${data.foto || data.Foto}`
      : null
    : data.foto || data.Foto
    ? `${serverAddress}/fotos/${data.foto || data.Foto}`
    : null;

  const title = isOfferOrDemand
    ? capitalizeFirstLetter(data.titul)
    : capitalizeFirstLetter(`${data.nomUsuari} ${data.cognomUsuari}`);

  const description = isOfferOrDemand
    ? capitalizeFirstLetter(data.descripcio)
    : capitalizeFirstLetter(data.presentacio);

  const getTransactionTypeDescription = (typeTransac, aplica_OD, language) => {
    let transactionType;
    if (aplica_OD === "O") {
      switch (typeTransac) {
        case "V":
          transactionType = "venta";
          break;
        case "L":
          transactionType = "alquiler";
          break;
        case "P":
          transactionType = "prestamo";
          break;
        case "G":
          transactionType = "regalo";
          break;
        default:
          transactionType = "servicio";
      }
    } else if (aplica_OD === "D") {
      switch (typeTransac) {
        case "V":
          transactionType = "compra";
          break;
        case "L":
          transactionType = "alquiler";
          break;
        case "P":
          transactionType = "prestado";
          break;
        case "G":
          transactionType = "regalado";
          break;
        default:
          transactionType = "servicio";
      }
    }
    return translations[language][transactionType];
  };

  const dateText = data.dataOferta
    ? getTimeDifference(data.dataOferta)
    : data.dataDemanda
    ? getTimeDifference(data.dataDemanda)
    : data.dataAlta
    ? getTimeDifference(data.dataAlta)
    : "";

  return (
    <TouchableOpacity onPress={handlePress}>
      <View style={styles.cardContainer}>
        <View style={styles.cardBackground} />
        <Image
          style={[
            styles.cardImage,
            data.tipusUsuari === "Premium" ? styles.premiumBorder : styles.standardBorder,
            isOfferOrDemand ? styles.offerDemandImageStyle : {},
          ]}
          resizeMode="cover"
          source={
            imageUrl
              ? { uri: imageUrl }
              : data.id_usuari != null
              ? profileImages[data.id_usuari % 10]
              : require("../img/profiles/default.png")
          }
        />

        {data.tipusUsuari === "Premium" && (
          <Text style={styles.proText}>PRO</Text>
        )}

        <Text
          style={[Fonts.poppinsMedium14, styles.nameText]}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {title}
        </Text>
        <Text
          style={[Fonts.poppinsRegular14, styles.descriptionText]}
          numberOfLines={4}
        >
          {description}
        </Text>
        {roundedDistancia !== null && (
          <Text style={[Fonts.poppinsRegular12, styles.kmText]}>
            {`${roundedDistancia} Km`}
          </Text>
        )}
        {isOfferOrDemand ? (
          <Text style={[Fonts.poppinsRegular12, styles.transactionText]}>
            {getTransactionTypeDescription(
              data.tipoTransac,
              data.aplica_OD,
              language
            )}
          </Text>
        ) : (
          <View style={styles.valoracionContainer}>
            <Text style={[Fonts.poppinsRegular12, styles.ratingText]}>
              {roundedValoracio}
            </Text>
            <Image
              style={styles.iconText}
              resizeMode="cover"
              source={require("../img/icons/puntuation.png")}
            />
          </View>
        )}
        <Text style={[styles.timeText]}>{dateText}</Text>
      </View>
      {renderNeedRegisterModal()}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: 143,
    height: 227,
    marginRight: 16,
    marginBottom: 15,
    marginTop: 8,
    position: "relative",
    borderWidth: 1,
    borderColor: Colors.grayLight,
    borderRadius: 16, // Asegúrate de que coincida con el borderRadius del cardBackground
  },
  cardBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: 15,
    backgroundColor: Colors.grayLighter, // Color claro para el fondo en lugar de blanco
    /* Comentar la sombra para todos los dispositivos
    ...Platform.select({
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: {
          width: 0,
          height: 4,
        },
        shadowRadius: 6,
        shadowOpacity: 1,
      },
      android: {
        elevation: 5,
      },
      default: {
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: { width: 1, height: 1 },
        shadowRadius: 6,
        shadowOpacity: 1,
      },
    }),
    */
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  cardImage: {
    position: "absolute",
    left: 27,
    top: 9,
    borderRadius: 69,
    width: 89,
    height: 83,
    backgroundColor: Colors.primary,
  },
  proText: {
    position: "absolute",
    top: 4,
    left: 4,
    color: Colors.secondary,
    ...Fonts.poppinsSemiBold14,
  },
  icon: {
    position: "absolute",
    left: 103,
    top: 8,
    width: 16,
    height: 14,
  },
  nameText: {
    left: 11,
    top: 97,
    width: 132,
    marginBottom: -25,
    lineHeight: 16 * 1.1,
  },
  descriptionText: {
    left: 11,
    top: 124,
    overflow: "hidden",
    width: 127,
  },
  kmText: {
    position: "absolute",
    top: 210,
    right: 10, // Mantenemos el km en la misma posición pero damos más espacio a la izquierda
    textAlign: "right", // Alineamos a la derecha
    maxWidth: 100, // Ajustamos el ancho máximo para que el texto no se salga de la tarjeta
  },
  ratingText: {
    marginRight: 8,
    marginBottom: -6,
    marginLeft: 2,
  },
  iconText: {
    width: 16,
    height: 16,
    marginBottom: -2,
  },
  premiumBorder: {
    borderWidth: 2,
    borderColor: Colors.secondary,
  },
  standardBorder: {
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  valoracionContainer: {
    position: "absolute",
    left: 8,
    bottom: 6,
    flexDirection: "row",
    alignItems: "center",
  },
  transactionText: {
    position: "absolute",
    left: 8,
    top: 210,
  },
  promotionCard: {
    borderRadius: 15,
    backgroundColor: Colors.primary,
    justifyContent: "center",
    alignItems: "center",
    /* Comentar la sombra para todos los dispositivos
    ...Platform.select({
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: {
          width: 0,
          height: 4,
        },
        shadowRadius: 6,
        shadowOpacity: 1,
      },
      android: {
        elevation: 5,
      },
      default: {
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: { width: 1, height: 1 },
        shadowRadius: 6,
        shadowOpacity: 1,
      },
    }),
    */
  },
  promotionCardTextPart1: {
    color: Colors.white,
    ...Fonts.poppinsMedium16,
    textAlign: "center",
  },
  promotionCardTextPart2: {
    color: Colors.white,
    ...Fonts.poppinsMedium16,
    textAlign: "center",
    marginTop: 12,
  },
  timeText: {
    position: "absolute",
    top: 6,
    right: 6,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    color: Colors.white,
    paddingHorizontal: 3,
    paddingTop: Platform.select({ web: 1, default: 3 }),
    paddingBottom: 1,
    borderRadius: 5,
    ...Fonts.poppinsRegular12,
    overflow: "hidden",
  },
});

export default CardSVertical;
